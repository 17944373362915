/**
 * Asynchronously loads the component for AddCandidatePage
 */

import { lazyLoad } from "utils/loadable";

export const AddEditCandidate = lazyLoad(
  () => import("./index"),
  (module) => module.AddEditCandidate
);
