import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import createSlice from './createStore';

function createSliceParam() {
return (set, get) => ({
    ...createSlice(set, get),
});
}

const useStore = create(devtools(createSliceParam()));

export {useStore}