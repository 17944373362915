import apiRequest from "./apiRequest";
import {
  getApiUrl,
  getDownloadFileUrl,
  getJobSharedWithUrl,
  getCandidateListUrl,
  getJobListUrl,
  getTransactionApiUrl,
  getDashboardDetailsApi,
  getTransactionApplicationApiUrl,
  getCandidateInterviewStageUrl,
  getCandidateSelectedStageUrl,
  getCandidateOfferedStageUrl,
  getDailyMandatesAddedUrl,
  getPipelineReportUrl,
  getNewJoinersUrl,
  UserDetailsByApplicableUserIdAPIurl
} from "./apiConfig"

import { baseUrlRoute, secureBaseUrlRoute, uploadUrlRoute } from "./constants"
import axios from "axios"

export const login = async (payload) => {
  const { url, httpMethod } = getApiUrl(payload, "login");
  const accessToken = null;
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchStates = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "stateList");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchCities = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "cityList");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchMasterUIData = async (accessToken) => {
  const payload = null;
  const { url, httpMethod } = getApiUrl(payload, "masterUI");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchjobListByUserId = async (accessToken) => {
  const payload = null;
  const { url, httpMethod } = getApiUrl(payload, "jobDetail");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchJobList = async (payload, accessToken) => {
  const { url, httpMethod } = getJobListUrl(payload, "fetchJobList");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchMandateDetailByMandateId = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "mandateDetail");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchClientDetail = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "clientDetail");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const upsertMandate = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "upsertMandate");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const uploadFileToCloud = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "uploadFile");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const getFileFromCloud = async (payload, accessToken) => {
  // const { url, httpMethod } = getDownloadFileUrl(
  //   payload,
  //   "downloadFile",
  //   accessToken
  // )
  // var ref = document.createElement("a")
  // ref.setAttribute("href", url)
  // ref.setAttribute("target", "_blank")
  // document.body.appendChild(ref)
  // ref.click()
  // ref.remove()
  const { url, httpMethod } = getApiUrl(payload, "getFile");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const downloadFileFromCloud = async (payload, accessToken) => {
  const { url, httpMethod } = getDownloadFileUrl(
    payload,
    "downloadFile",
    accessToken
  );
  const fileName = "download";
  var ref = document.createElement("a");
  ref.setAttribute("href", url);
  ref.setAttribute("download", fileName);
  document.body.appendChild(ref);
  ref.click();
  ref.remove();
  // return await apiRequest(url, payload, httpMethod, accessToken)
};

export const deleteFileFromCloud = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "deleteFile");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const upsertAssignedConsultants = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "upsertAssignDetail");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchAssignedConsultantsByJobId = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "getAssignedDetail");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const deleteAssignedConsultant = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "deleteAssignedConsultant");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const upsertSharedConsultants = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "upsertShareDetail");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchSharedConsultantsByJobIdAssignmentId = async (
  payload,
  accessToken
) => {
  const { url, httpMethod } = getJobSharedWithUrl(payload, "getSharedDetail");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const deleteSharedConsultant = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "deleteSharedConsultant");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const closeJobRequirement = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "closeJob");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchCandidates = async (payload, accessToken) => {
  const { url, httpMethod } = getCandidateListUrl(payload, "fetchCandidates");
  return await apiRequest(url, payload, httpMethod, accessToken);
};
export const fetchCandidate = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "fetchCandidate");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const upsertCandidate = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "upsertCandidate");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const deleteCandidate = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "deleteCandidate");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const blackListCandidate = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "blackListCandidate");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const addCandidateNotes = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "addCandidateNotes");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const upsertCandidatePreference = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "upsertCandidatePreference");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchCandidateHistory = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "fetchCandidateHistory");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const updateApplicantStage = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "updateApplicantStage");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const validateApplicantRLCStage = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "validateApplicantRLCStage");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const rejectCandidate = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "rejectCandidate");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchRLC = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "viewRLC");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const shortlistApplicant = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "shortlistApplicant");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchShortlistedApplicantDetail = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(
    payload,
    "fetchShortlistedApplicantDetail"
  );
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const scheduleInterviewDetails = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "scheduleInterviewDetails");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchScheduledInterviewDetail = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(
    payload,
    "fetchScheduledInterviewDetail"
  );
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const updateInterviewFeedback = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "updateInterviewFeedback");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const shareInterviewFeedback = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "shareInterviewFeedback");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const markSelectApplicant = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "markSelectApplicant");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const requestApplicantDocuments = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "requestApplicantDocuments");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const getRequestedApplicantDocuments = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(
    payload,
    "getRequestedApplicantDocuments"
  );
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const uploadApplicantDocuments = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "uploadApplicantDocuments");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const viewApplicantDocuments = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "viewApplicantDocuments");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const shareApplicantDocuments = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "shareApplicantDocuments");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const validateMakeOffer = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "validateMakeOffer");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const upsertOfferDetails = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "upsertOfferDetails");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchOfferDetails = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "getOfferDetails");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const shareOfferInternal = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "shareOfferInternal");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const shareJobDescription = async (payload, accessToken) => {
  const { url, httpMethod } = getApiUrl(payload, "shareJobDescription");
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchTransactionDetails = async (payload, accessToken) => {
  const { url, httpMethod } = getTransactionApiUrl(
    payload,
    "getTransactionDetailsData"
  );
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchCandidateInterviewStage = async (payload, accessToken) => {
  const { url, httpMethod } = getCandidateInterviewStageUrl(payload, "getCandidateInterviewStage")
  return await apiRequest(url, payload, httpMethod, accessToken)
};


export const fetchCandidateSelectedStage = async (payload, accessToken) => {
  const { url, httpMethod } = getCandidateSelectedStageUrl(payload, "getCandidateSelectedStage")
  return await apiRequest(url, payload, httpMethod, accessToken)
};

export const fetchCandidateOfferedStage = async (payload, accessToken) => {
  const { url, httpMethod } = getCandidateOfferedStageUrl(payload, "getCandidateOfferedStage")
  return await apiRequest(url, payload, httpMethod, accessToken)
};

export const fetchDailyMandatesAdded = async (payload, accessToken) => {
  const { url, httpMethod } = getDailyMandatesAddedUrl(payload, "getDailyMandatesAdded")
  return await apiRequest(url, payload, httpMethod, accessToken)
};

export const fetchPipelineReport = async (payload, accessToken) => {
  const { url, httpMethod } = getPipelineReportUrl(payload, "getPipelineReport")
  return await apiRequest(url, payload, httpMethod, accessToken)
};

export const fetchNewJoiners = async (payload, accessToken) => {
  const { url, httpMethod } = getNewJoinersUrl(payload, "getNewJoiners")
  return await apiRequest(url, payload, httpMethod, accessToken)
};

export const fetchTransactionApplicationDetails = async (accessToken) => {
  const { url, httpMethod } = getTransactionApplicationApiUrl(
    "getTransactionApplicationData"
  );
  return await apiRequest(url, {}, httpMethod, accessToken);
};


export const fetchGetDashboaredQuantityCounter = async (payload, accessToken) => {
  const { url, httpMethod } = getTransactionApiUrl(
    payload,
    "getDashboaredQuantityCounter"
  );
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchUserDetailsByApplicableUserId = async (payload, accessToken) => {
  const { url, httpMethod } = UserDetailsByApplicableUserIdAPIurl(
    payload,
    "getUserDetailsByApplicableUserId"
  );
  return await apiRequest(url, payload, httpMethod, accessToken);
};

export const fetchJobDetailsByUserId = async (accessToken) => {
  const { url, httpMethod } = getTransactionApplicationApiUrl(
    "getJobDetailsByUserId"
  );
  return await apiRequest(url, {}, httpMethod, accessToken);
};

export const fetchHyreDashboardDetails = async (payload, accessToken) => {
  const { url, httpMethod } = getDashboardDetailsApi(
    payload,
    "getHyreDashboardDetails"
  );
  return await apiRequest(url, payload, httpMethod, accessToken);
};
