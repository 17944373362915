import axios from "axios";

export function checkStatus(response: any) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response?.response?.status === 401) {
    // alert("Your session is expired or not active, Please login again");
    return response.response;
  } else if (
    response.response.status >= 400 &&
    response.response.status < 500
  ) {
    // alert("Something went wrong, Please login and try again");
    return response.response;
  } else if (
    response.response.status >= 500 &&
    response.response.status < 600
  ) {
    // alert(
    //   "There is an error in fetching some data, please login and try again"
    // );
    return response.response;
  }
  // return response;
}

export default function apiRequest(url, payload, httpMethod, accessToken) {
  switch (httpMethod) {
    case "GET":
      return axios
        .get(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            token: `${accessToken}`,
          },
        })
        .then(checkStatus)
        .catch(checkStatus);
    case "POST":
      return axios
        .post(url, payload.data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            token: `${accessToken}`,
          },
        })
        .then(checkStatus)
        .catch(checkStatus);
    case "PUT":
      return axios
        .put(url, payload.data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            token: `${accessToken}`,
          },
        })
        .then(checkStatus)
        .catch(checkStatus);
    case "DELETE":
      return axios
        .delete(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            token: `${accessToken}`,
          },
        })
        .then(checkStatus)
        .catch(checkStatus);
    default:
  }
}
