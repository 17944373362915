import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { colors } from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.fontPrimary,
    },
  },
  typography: {
    fontFamily: "Source Sans Pro",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "13px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: 110,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondary,
          borderRadius: "4px",
          "& .Mui-disabled": { backgroundColor: colors.lightGreyBorder },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: `${colors.primary}10`,
            borderLeft: `5px solid ${colors.primary}`,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#c4c4c4 #ffffff",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#ffffff",
            width: "0.7rem",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#c4c4c4",
            minHeight: 20,
            border: "3px solid #ffffff",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#b5b5b5",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#b5b5b5",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#b5b5b5",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#ffffff",
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
