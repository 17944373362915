import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
  Grid,
  Box,
  Toolbar,
  List,
  CssBaseline,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Menu,
  Dashboard,
  BusinessCenter,
  Group,
  PersonSearch,
  BarChart,
  SupportAgent,
  ScreenSearchDesktop,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
} from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { useDrawerSlice } from "../../store/DrawerStore/slice/index";
import { drawerSelector } from "../../store/DrawerStore/slice/selectors";
import Header from "./Header";
import { colors } from "../../styles/colors";
import { useStore } from "../../store/index";

const drawerWidth = 240;

const drawerItems = [
  { name: "Dashboard", path: "/dashboard" },
  { name: "Hiring Requests", path: "/hiring-requests" },
  { name: "Mandates", path: "/mandates" },
  { name: "Candidates", path: "/candidates" },
  { name: "Search Profiles", path: "/profiles" },
  { name: "Reports", path: "/reports" },
];

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface IMiniDrawerProps {
  content: React.ReactNode;
}

interface IWidth {
  width: number | string;
}

export default function MiniDrawer(props: any) {
  const { content } = props;
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { actions } = useDrawerSlice();
  const {
    drawerName,
    drawerIndex,
    setDrawerName,
    setDrawerIndex,
  } = useStore((state) => ({
    drawerName: state.drawerName,
    setDrawerName: state.setDrawerName,
    drawerIndex: state.drawerIndex,
    setDrawerIndex: state.setDrawerIndex,
  }));
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [drawerFooterWidth, setDrawerFooterWidth] = React.useState<IWidth>({
    width: 0,
  });

  const getCurrentPathName = () => {
    return location.pathname === "/dashboard"
      ? "Dashboard"
      : location.pathname.startsWith("/hiring-requests")
      ? "Hiring Requests"
      : location.pathname.startsWith("/mandates")
      ? "Mandates"
      : location.pathname.startsWith("/candidates")
      ? "Candidates"
      : location.pathname === "/profiles"
      ? "Search Profiles"
      : "Reports";
  };

  const getCurrentPathIndex = () => {
    return location.pathname === "/dashboard"
      ? 0
      : location.pathname.startsWith("/hiring-requests")
      ? 1
      : location.pathname.startsWith("/mandates")
      ? 2
      : location.pathname.startsWith("/candidates")
      ? 3
      : location.pathname === "/profiles"
      ? 4
      : 5;
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  React.useEffect(() => {
    if (drawerOpen) {
      setDrawerFooterWidth({ width: drawerWidth });
    } else {
      setDrawerFooterWidth({
        width: drawerOpen ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up("sm")]: {
          width: `calc(${theme.spacing(8)} + 1px)`,
        },
      });
    }
  }, [drawerIndex, drawerName]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={drawerOpen || false}
        color="inherit"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(drawerOpen && { display: "none" }),
            }}
          >
            <Menu color="secondary" />
          </IconButton>
          <Header
            drawerName={drawerName}
            getCurrentPathName={getCurrentPathName}
          />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={drawerOpen || false}>
        <DrawerHeader sx={{ justifyContent: "center", height: 150 }}>
          {drawerOpen ? (
            <Grid
              item
              sx={{
                borderRadius: "3px",
                backgroundColor: `${colors.primary}40`,
                px: 7,
                py: 2,
              }}
            >
              <Typography sx={{ fontSize: "20px", color: colors.primary }}>
                HYRE
              </Typography>
            </Grid>
          ) : (
            <Grid
              item
              sx={{
                borderRadius: "3px",
                backgroundColor: `${colors.primary}40`,
                px: 2,
                py: 1,
              }}
            >
              <Typography sx={{ fontSize: "20px", color: colors.primary }}>
                H
              </Typography>
            </Grid>
          )}
        </DrawerHeader>
        <List>
          {drawerItems.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: drawerOpen ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  setDrawerName(item.name);
                  setDrawerIndex(index);
                  setDrawerOpen(drawerOpen || false);

                  history.push(item.path);
                }}
                selected={index === (drawerIndex || getCurrentPathIndex())}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index === 0 && (
                    <Dashboard sx={{ color: colors.green }} fontSize="small" />
                  )}
                  {index === 1 && (
                    <ScreenSearchDesktop
                      sx={{ color: colors.orange }}
                      fontSize="small"
                    />
                  )}
                  {index === 2 && (
                    <BusinessCenter
                      sx={{ color: colors.orange }}
                      fontSize="small"
                    />
                  )}
                  {index === 3 && (
                    <Group sx={{ color: colors.purple }} fontSize="small" />
                  )}
                  {index === 4 && (
                    <PersonSearch
                      sx={{ color: colors.blue }}
                      fontSize="small"
                    />
                  )}
                  {index === 5 && (
                    <BarChart
                      sx={{ color: colors.blueGreen }}
                      fontSize="small"
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{
                    opacity: drawerOpen ? 1 : 0,
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <DrawerHeader
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            position: "absolute",
            top: "auto",
            bottom: 0,
            borderTop: `1px solid ${colors.lightGreyBorder}`,
            width: drawerFooterWidth,
          }}
        >
          {drawerOpen ? (
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Grid container alignItems={"center"}>
                  <Grid item>
                    <IconButton>
                      <SupportAgent color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: colors.primary }}>
                      Help Desk
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton onClick={(e) => handleDrawerClose()}>
                  <KeyboardDoubleArrowLeft />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            <IconButton onClick={(e) => handleDrawerOpen()}>
              <KeyboardDoubleArrowRight />
            </IconButton>
          )}
        </DrawerHeader>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2, maxHeight: "100vh", overflow: "scroll" }}
      >
        <DrawerHeader />
        {content}
      </Box>
    </Box>
  );
}
