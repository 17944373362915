import * as React from "react"
import { useLocation, useHistory, NavLink } from "react-router-dom"
import {
  Grid,
  Avatar,
  Badge,
  Typography,
  IconButton,
  Breadcrumbs,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"
import AppsIcon from "@mui/icons-material/Apps"
import { colors } from "../../styles/colors"

interface IHeaderProps {
  drawerName: any
  getCurrentPathName: () => string
}

interface IBreadcrumb {
  name: string
  link: string
}

const Header = (props: IHeaderProps) => {
  const { drawerName, getCurrentPathName } = props
  const location = useLocation()
  const history = useHistory()
  const [breadcrumbs, setBreadcrumbs] = React.useState<IBreadcrumb[]>([])
  const [hover, setHover] = React.useState(false)

  const getBreadCrumbParts = () => {
    const crumbs: IBreadcrumb[] = []
    if (location.pathname === "/dashboard") {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "",
      })
    }
    if (location.pathname === "/hiring-requests") {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "",
      })
    }
    if (location.pathname === "/hiring-requests/add-hiring-request") {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/hiring-requests",
      })
      crumbs.push({
        name: "Add Request",
        link: "",
      })
    }
    if (location.pathname.startsWith("/hiring-requests/edit-hiring-request")) {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/hiring-requests",
      })
      crumbs.push({
        name: "Edit Request",
        link: "",
      })
    }
    if (location.pathname === "/mandates") {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "",
      })
    }
    if (location.pathname === "/mandates/add-mandate") {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/mandates",
      })
      crumbs.push({
        name: "Add Mandate",
        link: "",
      })
    }
    if (location.pathname.startsWith("/mandates/edit-mandate")) {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/mandates",
      })
      crumbs.push({
        name: "Edit Mandate",
        link: "",
      })
    }
    if (location.pathname.startsWith("/mandates/clone-mandate")) {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/mandates",
      })
      crumbs.push({
        name: "Clone Mandate",
        link: "",
      })
    }
    if (location.pathname.startsWith("/mandates/rlc")) {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/mandates",
      })
      crumbs.push({
        name: "Recuirement Life Cycle",
        link: "",
      })
    }
    if (location.pathname.startsWith("/mandates/assign-job")) {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/mandates",
      })
      crumbs.push({
        name: "Assign Job",
        link: "",
      })
    }
    if (location.pathname.startsWith("/mandates/share-job")) {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/mandates",
      })
      crumbs.push({
        name: "Share Job",
        link: "",
      })
    }
    if (location.pathname === "/candidates") {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "",
      })
    }
    if (location.pathname === "/candidates/add-candidate") {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/candidates",
      })
      crumbs.push({
        name: "Add Candidate",
        link: "",
      })
    }
    if (location.pathname.startsWith("/candidates/edit-candidate")) {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/candidates",
      })
      crumbs.push({
        name: "Edit Candidate",
        link: "",
      })
    }
    if (location.pathname.startsWith("/candidates/capture-preference")) {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "/candidates",
      })
      crumbs.push({
        name: "Capture Preference",
        link: "",
      })
    }
    if (location.pathname === "/profiles") {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "",
      })
    }
    if (location.pathname === "/reports") {
      crumbs.push({
        name: drawerName || getCurrentPathName(),
        link: "",
      })
    }
    setBreadcrumbs(crumbs)
  }

  React.useEffect(() => {
    getBreadCrumbParts()
  }, [])

  return (
    <Grid container alignItems={"center"} justifyContent={"space-between"}>
      <Grid item>
        <Grid container alignItems={"center"}>
          {breadcrumbs && breadcrumbs.length > 2 && (
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackOutlinedIcon fontSize="small" />
            </IconButton>
          )}
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<Typography fontSize="20px">/</Typography>}
          >
            {breadcrumbs && breadcrumbs.length === 1 && (
              <Typography
                fontSize={"16px"}
                fontWeight={400}
                color={colors.fontDark}
              >
                {breadcrumbs[0].name || ""}
              </Typography>
            )}
            {breadcrumbs &&
              breadcrumbs.length > 1 &&
              breadcrumbs.map((crumb, index) => (
                <div key={index}>
                  {index < breadcrumbs.length - 1 && (
                    <NavLink
                      role="button"
                      to={crumb.link}
                      style={{
                        color: colors.fontPrimary,
                        fontSize: "16px",
                        fontWeight: 400,
                        textDecoration: hover ? "underline" : "none",
                      }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      {crumb?.name || ""}
                    </NavLink>
                  )}
                  {index === breadcrumbs.length - 1 && (
                    <Typography
                      fontSize={"16px"}
                      fontWeight={400}
                      color={colors.fontDark}
                    >
                      {breadcrumbs[breadcrumbs.length - 1].name || ""}
                    </Typography>
                  )}
                </div>
              ))}
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          spacing={1}
        >
          <Grid item>
            <IconButton>
              <AddIcon color="primary" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <SearchIcon color="primary" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <Badge
                badgeContent={"02"}
                sx={{
                  ".MuiBadge-badge": {
                    backgroundColor: colors.danger,
                    color: colors.secondary,
                  },
                }}
              >
                <NotificationsNoneOutlinedIcon color="primary" />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <AppsIcon color="primary" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
              <Avatar sx={{ width: 30, height: 30, padding: 1 }}>H</Avatar>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Header
