/**
 * Asynchronously loads the component for LoginPage
 */

import { lazyLoad } from "utils/loadable";

export const Login = lazyLoad(
  () => import("./index"),
  (module) => module.Login
);
