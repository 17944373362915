import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../types/RootState";

export const mySelector = (state: RootState) => {
  return state.drawer;
};

const drawerSelector = createSelector(mySelector, (state) => {
  return state;
});

export { drawerSelector };
