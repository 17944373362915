import * as React from "react";
import styled from "styled-components/macro";
import { P } from "./P";
import { Helmet } from "react-helmet-async";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { colors } from "../../../styles/colors";

export function SomethingWentWrongPage() {
  const history = useHistory();

  setTimeout(() => {
    history.push("/");
  }, 3000);

  return (
    <>
      <Helmet>
        <title>Something Went Wrong</title>
        <meta name="description" content="Something Went Wrong" />
      </Helmet>
      <Wrapper>
        <Title>Something Went Wrong</Title>
        <P>Please login and try again</P>
        {/* <Button
          variant="contained"
          size="small"
          sx={{ color: colors.secondary }}
          onClick={() => {
            history.push("/");
          }}
        >
          Login
        </Button> */}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
`;

const Title = styled.div`
  font-weight: bold;
  color: black;
  font-size: 3rem;

  span {
    font-size: 3rem;
  }
`;
