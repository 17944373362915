/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";

// Use consistent styling
import "sanitize.css/sanitize.css";

// Import root app
import { App } from "app";

import { HelmetProvider } from "react-helmet-async";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";

import { configureAppStore } from "store/configureStore";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import reportWebVitals from "reportWebVitals";

// Initialize languages
import "./locales/i18n";

const store = configureAppStore();
const MOUNT_NODE = document.getElementById("root") as HTMLElement;

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </HelmetProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>,
  MOUNT_NODE
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(["./locales/i18n"], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
