import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit"; // Importing from `utils` makes them more type-safe ✅
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { IDrawerState } from "./types";
import drawerSaga from "./saga";

// The initial state of the Dashboard
export const initialState: IDrawerState = {
  name: "Dashboard",
  index: 0,
  open: false,
};

const slice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    changeDrawerItem(state, action: PayloadAction<IDrawerState>) {
      state.name = action.payload?.name;
      state.index = action.payload?.index;
      state.open = action.payload?.open;
    },
    changeDrawerStatus(state, action: PayloadAction<IDrawerState>) {
      state.name = action.payload?.name;
      state.index = action.payload?.index;
      state.open = action.payload?.open;
    },
  },
});

/**
 * `actions` will be used to trigger change in the state from where ever you want
 */
export const { actions: drawerActions } = slice;

export const useDrawerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: drawerSaga });
  return { actions: slice.actions };
};
