const createSlice = (set, get) => ({
  drawerName: "",
  setDrawerName: (drawerName) => {
    set({ drawerName });
  },
  drawerIndex: null,
  setDrawerIndex: (drawerIndex) => {
    set({ drawerIndex });
  },
  jobClassificationHeader: false,
  setJobClassificationHeader: (jobClassificationHeader) => {
    set({ jobClassificationHeader });
  },
  clientExpectationHeader: false,
  setClientExpectationHeader: (clientExpectationHeader) => {
    set({ clientExpectationHeader });
  },
  sessionDetail: <any>[],
  setSessionDetail: (sessionDetail) => {
    set({ sessionDetail });
  },
  masterUI: <any>[],
  setMasterUI: (masterUI) => {
    set({ masterUI });
  },
  jobDetail: <any>[],
  setJobDetail: (jobDetail) => {
    set({ jobDetail });
  },
  mandateList: <any>[],
  setMandateList: (mandateList) => {
    set({ mandateList });
  },
  selectedJobRequirement: <any>{},
  setSelectedJobRequirement: (selectedJobRequirement) => {
    set({ selectedJobRequirement });
  },
  selectedJobRequirementAdditionalDetails: <any>{},
  setSelectedJobRequirementAdditionalDetails: (
    selectedJobRequirementAdditionalDetails
  ) => {
    set({ selectedJobRequirementAdditionalDetails });
  },
  selectedInterviewLevels: <any>[],
  setSelectedInterviewLevels: (selectedInterviewLevels) => {
    set({ selectedInterviewLevels });
  },
  selectedJobClassificationDetail: <any>{},
  setSelectedJobClassificationDetail: (selectedJobClassificationDetail) => {
    set({ selectedJobClassificationDetail });
  },
  selectedClientExpectationDetail: <any>[],
  setSelectedClientExpectationDetail: (selectedClientExpectationDetail) => {
    set({ selectedClientExpectationDetail });
  },
  selectedJob: <any>{},
  setSelectedJob: (selectedJob) => {
    set({ selectedJob });
  },
  candidateList: <any>[],
  setCandidateList: (candidateList) => {
    set({ candidateList });
  },
  selectedCandidate: <any>{},
  setSelectedCandidate: (selectedCandidate) => {
    set({ selectedCandidate });
  },
  rlcData: <any>{},
  setRlcData: (rlcData) => {
    set({ rlcData });
  },
  selectedCandidateInRlc: <any>{},
  setSelectedCandidateInRlc: (selectedCandidateInRlc) => {
    set({ selectedCandidateInRlc });
  },
  selectedCandidateInRlcArrDetail: <any>[],
  setSelectedCandidateInRlcArrDetail: (selectedCandidateInRlcArrDetail) => {
    set({ selectedCandidateInRlcArrDetail });
  },
});

export default createSlice;
