import React from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  InputAdornment,
  Button,
  InputLabel,
  OutlinedInput,
  Avatar,
  CssBaseline,
  Paper,
  useMediaQuery,
  Box,
} from "@mui/material";
import { withFormik } from "formik";
import * as Yup from "yup";
import { colors } from "../../../../styles/colors";
import decrypt from "../../../../utils/security/decrypt";
import encrypt from "../../../../utils/security/encrypt";
import { useMutation } from "@tanstack/react-query";
import { useStore } from "../../../../store/index";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Drawer from "../../../components/Drawer";
import Footer from "app/components/Footer";
import theme from "../../../../styles/theme";
import {
  upsertCandidatePreference,
  fetchCandidate,
} from "../../../../query/api";

function CapturePreferenceContent(props) {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    touched,
    setFieldValue,
    handleSubmit,
  } = props;
  const matchesXs = useMediaQuery(theme.breakpoints.up("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const {
    company,
    noticePeriodArr,
    jobTypeArr,
    industryArr,
    employmentTypeArr,
    selectedCandidate,
  } = useStore((state) => ({
    company: state.sessionDetail.Company,
    jobTypeArr: state.masterUI.FunctionalList,
    industryArr: state.masterUI.IndustryList,
    employmentTypeArr: state.masterUI.LstEmploymentType,
    noticePeriodArr: state.masterUI.LstNoticePeriod,
    selectedCandidate: state.selectedCandidate,
  }));

  const { ApplicantDetails, ApplicantSkillDetails } = selectedCandidate;

  const [timer, setTimer] = React.useState<any>(null);
  const payFrequencyArr = [
    {
      Id: 1,
      Name: "Weekly",
    },
    {
      Id: 2,
      Name: "Monthly",
    },
    {
      Id: 3,
      Name: "Fortnight",
    },
    {
      Id: 4,
      Name: "Yearly",
    },
    {
      Id: 5,
      Name: "Quarterly",
    },
    {
      Id: 6,
      Name: "Custom",
    },
  ];

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} spacing={2}>
          <Box
            color={colors.fontDark}
            fontSize="16px"
            fontWeight={600}
            mt={2}
            mb={0}
            ml={2}
          >
            Capture Preference
            <hr
              style={{
                border: `1px solid ${colors.danger}`,
                width: 25,
                marginRight: "100%",
                marginTop: 0.5,
              }}
            />
          </Box>
        </Grid>
        <Grid item container md={12} alignItems={"center"} spacing={1}>
          <Grid item xs={12} sm={6} md={1.5}>
            <Paper
              elevation={3}
              sx={{
                height: "110px",
                width: "110px",
                borderRadius: "55px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ height: "100px", width: "100px" }}>
                {ApplicantDetails?.FirstName?.charAt(0).toUpperCase() +
                  ApplicantDetails?.LastName?.charAt(0).toUpperCase()}
              </Avatar>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid>
              <Typography
                sx={{
                  color: colors.fontDark,
                  fontSize: "16px",
                  fontWeight: 600,
                  mb: 0.25,
                }}
              >
                {ApplicantDetails?.FirstName &&
                ApplicantSkillDetails[0] &&
                ApplicantSkillDetails[0]?.YearsOfExperience
                  ? `${ApplicantDetails?.FirstName}(${ApplicantSkillDetails[0]?.YearsOfExperience} Years experience)`
                  : ""}
              </Typography>
              <Typography>
                Applicant Sourced by John Doe on 29 Jan 2019 - dummy data
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} lg={4}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="contact">
              Current CTC
            </InputLabel>
            <TextField
              id="currentCTC"
              value={values.currentCTC}
              onChange={handleChange}
              fullWidth={true}
              inputProps={{ style: { fontSize: 14 } }}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                },
              }}
            />
            {touched?.currentCTC && (
              <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
                {errors?.currentCTC}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="contact">
              Expected CTC
            </InputLabel>
            <TextField
              id="expectedCTC"
              value={values.expectedCTC}
              onChange={handleChange}
              fullWidth={true}
              inputProps={{ style: { fontSize: 14 } }}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                },
              }}
            />
            {touched?.expectedCTC && (
              <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
                {errors?.expectedCTC}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <InputLabel shrink htmlFor="contact">
            Notice Period
          </InputLabel>
          <Autocomplete
            id="noticePeriod"
            options={noticePeriodArr ? noticePeriodArr : []}
            getOptionLabel={(option) => option.Name}
            value={noticePeriodArr?.find((x) => x.Id === values.noticePeriod)}
            onChange={(e, value) => {
              setFieldValue("noticePeriod", value?.Id || "");
            }}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Choose..."
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: 14 },
                }}
              />
            )}
          />
          {touched?.noticePeriod && (
            <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
              {errors?.noticePeriod}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <InputLabel shrink htmlFor="first-name">
            Industry Type
          </InputLabel>
          <Autocomplete
            id="industryTypeExpected"
            options={industryArr ? industryArr : []}
            getOptionLabel={(option) => option.Name}
            value={industryArr?.find(
              (x) => x.Id === values.industryTypeExpected
            )}
            onChange={(e, value) =>
              setFieldValue("industryTypeExpected", value?.Id || "")
            }
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Choose..."
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: 14 },
                }}
              />
            )}
          />
          {touched?.industryTypeExpected && (
            <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
              {errors?.industryTypeExpected}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <InputLabel shrink htmlFor="jobtype">
            Job Type
          </InputLabel>
          <Autocomplete
            id="jobTypeExpected"
            options={jobTypeArr ? jobTypeArr : []}
            getOptionLabel={(option) => option.Name}
            value={jobTypeArr?.find((x) => x.Id === values.jobTypeExpected)}
            onChange={(e, value) =>
              setFieldValue("jobTypeExpected", value?.Id || "")
            }
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Choose..."
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: 14 },
                }}
              />
            )}
          />
          {touched?.jobTypeExpected && (
            <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
              {errors?.jobTypeExpected}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <InputLabel shrink htmlFor="contact">
            Designation
          </InputLabel>
          <OutlinedInput
            id="designationExpected"
            value={values.designationExpected}
            onChange={handleChange}
            fullWidth={true}
            inputProps={{ style: { fontSize: 14 } }}
            size="small"
          />
          {touched?.designationExpected && (
            <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
              {errors?.designationExpected}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <InputLabel shrink htmlFor="contact">
            Designation Level
          </InputLabel>
          <OutlinedInput
            id="designationLevelExpected"
            value={values.designationLevelExpected}
            onChange={handleChange}
            fullWidth={true}
            inputProps={{ style: { fontSize: 14 } }}
            size="small"
          />
          {touched?.designationLevelExpected && (
            <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
              {errors?.designationLevelExpected}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <InputLabel shrink htmlFor="contact">
            Employment Type
          </InputLabel>
          <Autocomplete
            id="employmentTypeExpected"
            options={employmentTypeArr ? employmentTypeArr : []}
            getOptionLabel={(option) => option.Name}
            value={employmentTypeArr?.find(
              (x) => x.Id === values.employmentTypeExpected
            )}
            onChange={(e, value) =>
              setFieldValue("employmentTypeExpected", value?.Id || "")
            }
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Choose..."
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: 14 },
                }}
              />
            )}
          />
          {touched?.employmentTypeExpected && (
            <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
              {errors?.employmentTypeExpected}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <InputLabel shrink htmlFor="contact">
            Preferred Job Location
          </InputLabel>
          <OutlinedInput
            id="jobLocationExpected"
            value={values.jobLocationExpected}
            onChange={handleChange}
            fullWidth={true}
            inputProps={{ style: { fontSize: 14 } }}
            size="small"
          />
          {touched?.jobLocationExpected && (
            <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
              {errors?.jobLocationExpected}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <InputLabel shrink htmlFor="first-name">
            Pay Frequency
          </InputLabel>
          <Autocomplete
            id="payFrequencyExpected"
            options={payFrequencyArr ? payFrequencyArr : []}
            getOptionLabel={(option) => option.Name}
            value={payFrequencyArr?.find(
              (x) => x.Id === values.payFrequencyExpected
            )}
            onChange={(e, value) =>
              setFieldValue("payFrequencyExpected", value?.Id || "")
            }
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Choose..."
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: 14 },
                }}
              />
            )}
          />
          {touched?.payFrequencyExpected && (
            <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
              {errors?.payFrequencyExpected}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={8}>
          <InputLabel shrink htmlFor="contact">
            Notes
          </InputLabel>
          <OutlinedInput
            id="notes"
            multiline
            rows={3}
            value={values.notes}
            onChange={handleChange}
            fullWidth={true}
            inputProps={{ style: { fontSize: 14 } }}
            size="small"
          />
          {touched?.notes && (
            <Typography sx={{ color: colors.danger, fontSize: "11px" }}>
              {errors?.notes}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Footer
        content={
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="text"
                sx={{ color: "Gray" }}
                size="small"
                onClick={() => history.push("/candidates")}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                sx={{ color: "white" }}
                onClick={() => {
                  if (timer) {
                    clearTimeout(timer);
                    setTimer(null);
                  }
                  setTimer(
                    setTimeout(() => {
                      handleSubmit();
                    }, 500)
                  );
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}

const CapturePreferenceFormik = (props) => {
  const { uriParams } = props;
  const history = useHistory();
  const key: any = sessionStorage.getItem("Key");
  const vector: any = sessionStorage.getItem("Vector");
  const accessToken = sessionStorage.getItem("Token");

  const { selectedCandidate, setSelectedCandidate } = useStore((state) => ({
    selectedCandidate: state.selectedCandidate,
    setSelectedCandidate: state.setSelectedCandidate,
  }));

  const { ApplicantExpectationDetails } = selectedCandidate;

  const {
    isLoading: updatePreferenceLoading,
    mutate: updatePreference,
    data: updatePreferenceResponse,
  } = useMutation((payload: any) =>
    upsertCandidatePreference(payload, accessToken)
  );
  React.useEffect(() => {
    if (updatePreferenceResponse) {
      try {
        const response = updatePreferenceResponse?.data
          ? JSON.parse(decrypt(updatePreferenceResponse?.data, key, vector))
          : "";
        console.log(response);
        history.push("/candidates");
      } catch (e: any) {
        console.log(e.message);
      }
    }
  }, [updatePreferenceLoading, updatePreferenceResponse]);

  React.useEffect(() => {
    if (updatePreferenceResponse && updatePreferenceResponse?.status === 200) {
      try {
        const response = updatePreferenceResponse?.data
          ? JSON.parse(decrypt(updatePreferenceResponse?.data, key, vector))
          : "";
        console.log(response);
        history.push("/candidates");
      } catch (e: any) {
        console.log(e.message);
      }
    } else if (
      updatePreferenceResponse &&
      updatePreferenceResponse?.status === 401
    ) {
      history.push("/session-expired");
    } else if (
      updatePreferenceResponse &&
      updatePreferenceResponse?.status >= 400 &&
      updatePreferenceResponse?.status < 500
    ) {
      history.push("/something-went-wrong");
    } else if (
      updatePreferenceResponse &&
      updatePreferenceResponse?.status >= 500 &&
      updatePreferenceResponse?.status < 600
    ) {
      history.push("/something-went-wrong");
    }
  }, [updatePreferenceResponse]);

  const {
    isLoading: getCandidateLoading,
    mutate: getCandidate,
    data: getCandidateResponse,
  } = useMutation((payload: any) => fetchCandidate(payload, accessToken));

  React.useEffect(() => {
    if (getCandidateResponse) {
      try {
        const response = getCandidateResponse?.data
          ? JSON.parse(decrypt(getCandidateResponse?.data, key, vector))
          : "";
        console.log(response);
        setSelectedCandidate(response?.Result);
      } catch (e: any) {
        console.log(e.message);
      }
    }
  }, [getCandidateLoading, getCandidateResponse]);

  React.useEffect(() => {
    if (getCandidateResponse && getCandidateResponse?.status === 200) {
      try {
        const response = getCandidateResponse?.data
          ? JSON.parse(decrypt(getCandidateResponse?.data, key, vector))
          : "";
        console.log(response);
        setSelectedCandidate(response?.Result || {});
      } catch (e: any) {
        console.log(e.message);
      }
    } else if (getCandidateResponse && getCandidateResponse?.status === 401) {
      history.push("/session-expired");
    } else if (
      getCandidateResponse &&
      getCandidateResponse?.status >= 400 &&
      getCandidateResponse?.status < 500
    ) {
      history.push("/something-went-wrong");
    } else if (
      getCandidateResponse &&
      getCandidateResponse?.status >= 500 &&
      getCandidateResponse?.status < 600
    ) {
      history.push("/something-went-wrong");
    }
  }, [getCandidateResponse]);

  React.useEffect(() => {
    if (uriParams?.candidateId) {
      const payload = {
        queryProps: encrypt(uriParams.candidateId, key, vector),
      };
      getCandidate(payload);
    }
  }, [uriParams?.candidateId]);

  const CapturePreferenceWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        currentCTC: ApplicantExpectationDetails?.CurrentCTC
          ? ApplicantExpectationDetails.CurrentCTC
          : 0,
        expectedCTC: ApplicantExpectationDetails?.ExpectedCTC
          ? ApplicantExpectationDetails.ExpectedCTC
          : 0,
        noticePeriod: ApplicantExpectationDetails?.NoticePeriod
          ? ApplicantExpectationDetails.NoticePeriod
          : "",
        industryTypeExpected: ApplicantExpectationDetails?.PreferredIndustry
          ? ApplicantExpectationDetails.PreferredIndustry
          : "",
        jobTypeExpected: ApplicantExpectationDetails?.PreferredJobType
          ? ApplicantExpectationDetails.PreferredJobType
          : "",
        designationExpected: ApplicantExpectationDetails?.ExpectedDesignation
          ? ApplicantExpectationDetails.ExpectedDesignation
          : "",
        designationLevelExpected:
          ApplicantExpectationDetails?.ExpectedDesignationLevel
            ? ApplicantExpectationDetails.ExpectedDesignationLevel
            : "",
        employmentTypeExpected:
          ApplicantExpectationDetails?.ExpectedEmploymentType
            ? ApplicantExpectationDetails.ExpectedEmploymentType
            : "",
        jobLocationExpected: ApplicantExpectationDetails?.ExpectedWorkLocation
          ? ApplicantExpectationDetails.ExpectedWorkLocation
          : "",
        payFrequencyExpected: ApplicantExpectationDetails?.PayFrequency
          ? ApplicantExpectationDetails.PayFrequency
          : "",
        notes: ApplicantExpectationDetails?.ExpectationNotes
          ? ApplicantExpectationDetails.ExpectationNotes
          : "",
        id: ApplicantExpectationDetails?.Id
          ? ApplicantExpectationDetails.Id
          : 0,
      };
    },

    validationSchema: Yup.object().shape({
      currentCTC: Yup.number()
        .required("This is a required field")
        .typeError("Please enter a valid CTC in numbers"),
      expectedCTC: Yup.number().when("currentCTC", {
        is: (val) => val !== -1,
        then: Yup.number()
          .required("This is a required field")
          .typeError("Please enter a valid CTC in numbers")
          .test({
            name: "min-max",
            message: "Expected CTC must be greater than the current CTC",
            test: function (value: any) {
              return value > this.parent.currentCTC;
            },
          }),
      }),
      noticePeriod: Yup.number().required("This is a required field"),
      industryTypeExpected: Yup.number().required("This is a required field"),
      jobTypeExpected: Yup.number().required("This is a required field"),
      designationExpected: Yup.string()
        .trim()
        .required("This is a required field"),
      designationLevelExpected: Yup.string()
        .trim()
        .required("This is a required field"),
      employmentTypeExpected: Yup.number().required("This is a required field"),
      jobLocationExpected: Yup.string()
        .trim()
        .required("This is a required field"),
      payFrequencyExpected: Yup.number().required("This is a required field"),
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      // console.log(">>>>>>>>>>>>>values", values, props)
      const preferencePayload = {
        ApplicantId: uriParams?.candidateId,
        CurrentCTC: values.currentCTC,
        ExpectedCTC: values.expectedCTC,
        ExpectedEmploymentType: values.employmentTypeExpected,
        ExpectedWorkLocation: values.jobLocationExpected,
        ExpectedDesignation: values.designationExpected,
        ExpectedDesignationLevel: values.designationLevelExpected,
        NoticePeriod: values.noticePeriod,
        PayFrequency: values.payFrequencyExpected,
        PreferredIndustry: values.industryTypeExpected,
        PreferredJobType: values.jobTypeExpected,
        ExpectationNotes: values.notes,
        Status: 1,
        Id: values.id,
      };
      console.log(preferencePayload);
      const payload: any = {
        data: encrypt(JSON.stringify(preferencePayload), key, vector),
      };
      updatePreference(payload);
    },
  })(CapturePreferenceContent);
  return <CapturePreferenceWrapped {...props} />;
};

export const CapturePreference = () => {
  const uriParams = useParams();
  return (
    <>
      <Helmet>
        <title>Candidate Preference</title>
      </Helmet>
      <Drawer content={<CapturePreferenceFormik uriParams={uriParams} />} />
    </>
  );
};
