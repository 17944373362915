export const colors = {
  primary: "#00C28F",
  secondary: "#FFFFFF",
  fontPrimary: "#585858",
  fontDark: "#333333",
  lightGreyBorder: "#E3E3E3",
  lightGreyIcon: "#949494",
  darkGrey: "#597694",
  success: "#31BF6D",
  danger: "#FF6F60",
  dangerLight: "#FF6A73",
  green: "#1AA956",
  green2: "#16A085",
  orange: "#F39C12",
  purple: "#6627CD",
  violet: "#9B59B6",
  blue: "#3498DB",
  blueGreen: "#1ABC9C",
  warning: "#FFAC27",
  successFillColor: "rgba(49, 191, 109, 0.2)",
  successBorderColor: "rgba(49, 191, 109, 0.8)",
  warningFillColor: "rgba(255, 172, 39, 0.2)",
  warningBorderColor: "rgba(255, 172, 39, 0.8)",
  dangerFillColor: "rgba(255, 111, 96, 0.2)",
  dangerBorderColor: "rgba(255, 111, 96, 0.8)",
  fontLighter: "rgba(85, 85, 85, 0.5)",
  lightGray: "#585858",
  // new added for dashboard
  fontLight: "#555555",
  primaryLight: "#F4F7FC",
  chartTitle: "#464255",
  primaryFill: "rgba(27, 126, 179, 0.09)",
  successFill: "rgba(17, 153, 142, 0.15)",
  warningFill: "rgba(240, 152, 25, 0.15)",
  dangerFill: "rgba(255, 94, 98, 0.15)",
};
