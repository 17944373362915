/**
 * Asynchronously loads the component for AddEditHiringRequests
 */

import { lazyLoad } from "utils/loadable";

export const AddEditHiringRequests = lazyLoad(
  () => import("./index"),
  (module) => module.AddEditHiringRequests
);
