import * as React from "react";
import { useSelector } from "react-redux";
import { Toolbar, IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { AppBar } from "./Drawer";
import { drawerSelector } from "../../store/DrawerStore/slice/selectors";

interface IFooterProps {
  content: React.ReactNode;
}

const Footer = (props: IFooterProps) => {
  const { content } = props;
  const selectedDrawerItem = useSelector(drawerSelector);
  return (
    <AppBar
      position="fixed"
      open={selectedDrawerItem?.open || false}
      color="inherit"
      sx={{
        top: "auto",
        bottom: 0,
        paddingRight: 2,
        minHeight: "58px",
        justifyContent: "center",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={{
            marginRight: 5,
            ...(selectedDrawerItem?.open && { display: "none" }),
          }}
        >
          <Menu color="secondary" />
        </IconButton>
        {content}
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
