import { baseUrlRoute, secureBaseUrlRoute, uploadUrlRoute } from "./constants";

export const apiConfig = {
  login: {
    name: "login",
    urlEndPoint: `${secureBaseUrlRoute}Security/AuthenticateUser`,
    httpMethod: "POST",
  },
  masterUI: {
    name: "masterUI",
    urlEndPoint: `${baseUrlRoute}Jobs/LoadJobMasterUI`,
    httpMethod: "GET",
  },
  stateList: {
    name: "stateList",
    urlEndPoint: `${baseUrlRoute}Location/GetStateListByCountryId?CountryId=`,
    httpMethod: "GET",
  },
  cityList: {
    name: "cityList",
    urlEndPoint: `${baseUrlRoute}Location/GetCityListByStateId?StateId=`,
    httpMethod: "GET",
  },
  clientDetail: {
    name: "clientDetail",
    urlEndPoint: `${baseUrlRoute}Client/GetClientDetailsById?Id=`,
    httpMethod: "GET",
  },
  jobDetail: {
    name: "jobDetail",
    urlEndPoint: `${baseUrlRoute}Jobs/GetJobDetailsByUserId`,
    httpMethod: "GET",
  },
  fetchJobList: {
    name: "fetchJobList",
    urlEndPoint: `${baseUrlRoute}Jobs/GetJobRequirements?`,
    httpMethod: "GET",
  },
  mandateDetail: {
    name: "mandateDetail",
    urlEndPoint: `${baseUrlRoute}Jobs/GetJobRequirementbyId?Id=`,
    httpMethod: "GET",
  },
  upsertMandate: {
    name: "upsertMandate",
    urlEndPoint: `${baseUrlRoute}Jobs/UpsertJobDetails`,
    httpMethod: "PUT",
  },
  closeJob: {
    name: "closeJob",
    urlEndPoint: `${baseUrlRoute}Jobs/CloseJobRequirement`,
    httpMethod: "PUT",
  },
  uploadFile: {
    name: "uploadFile",
    urlEndPoint: `${uploadUrlRoute}ObjectStorage/StoreObject`,
    httpMethod: "POST",
  },
  getFile: {
    name: "getFile",
    urlEndPoint: `${uploadUrlRoute}ObjectStorage/GetStoredObjectById?objStorageId=`,
    httpMethod: "GET",
  },
  downloadFile: {
    name: "downloadFile",
    urlEndPoint: `${uploadUrlRoute}GetObject/GetObject/`,
    httpMethod: "GET",
  },
  deleteFile: {
    name: "deleteFile",
    urlEndPoint: `${uploadUrlRoute}ObjectStorage/DeleteStoredObjectById?objStorageId=`,
    httpMethod: "DELETE",
  },
  upsertAssignDetail: {
    name: "upsertAssignDetail",
    urlEndPoint: `${baseUrlRoute}Jobs/UpsertJobRequirementAssignmentDetails`,
    httpMethod: "PUT",
  },
  getAssignedDetail: {
    name: "getAssignedDetail",
    urlEndPoint: `${baseUrlRoute}Jobs/GetJobJobRequirementAssignmentByJobId?jobRequirementId=`,
    httpMethod: "GET",
  },
  deleteAssignedConsultant: {
    name: "deleteAssignedConsultant",
    urlEndPoint: `${baseUrlRoute}Jobs/DeleteJobRequirementAssignmentDetails`,
    httpMethod: "PUT",
  },
  upsertShareDetail: {
    name: "upsertShareDetail",
    urlEndPoint: `${baseUrlRoute}Jobs/UpsertJobRequirementSharedDetails`,
    httpMethod: "PUT",
  },
  getSharedDetail: {
    name: "getSharedDetail",
    urlEndPoint: `${baseUrlRoute}Jobs/GetjobRequirementSharedDetails?jobRequirementId=`,
    httpMethod: "GET",
  },
  deleteSharedConsultant: {
    name: "deleteSharedConsultant",
    urlEndPoint: `${baseUrlRoute}Jobs/DeleteJobRequirementSharedDetails`,
    httpMethod: "PUT",
  },
  fetchCandidates: {
    name: "fetchCandidates",
    urlEndPoint: `${baseUrlRoute}Applicants/GetApplicantDetails?`,
    httpMethod: "GET",
  },
  fetchCandidate: {
    name: "fetchCandidate",
    urlEndPoint: `${baseUrlRoute}Applicants/GetApplicantDetailsById?Id=`,
    httpMethod: "GET",
  },
  upsertCandidate: {
    name: "upsertCandidate",
    urlEndPoint: `${baseUrlRoute}Applicants/UpsertApplicantDetails`,
    httpMethod: "PUT",
  },
  deleteCandidate: {
    name: "deleteCandidate",
    urlEndPoint: `${baseUrlRoute}Applicants/DeleteApplicantDetails`,
    httpMethod: "PUT",
  },
  blackListCandidate: {
    name: "blackListCandidate",
    urlEndPoint: `${baseUrlRoute}Applicants/MarkApplicantAsBlackList`,
    httpMethod: "PUT",
  },
  addCandidateNotes: {
    name: "addCandidateNotes",
    urlEndPoint: `${baseUrlRoute}Applicants/UpdateApplicantNotes`,
    httpMethod: "PUT",
  },
  upsertCandidatePreference: {
    name: "upsertCandidatePreference",
    urlEndPoint: `${baseUrlRoute}Applicants/UpdateApplicantExpectationDetails`,
    httpMethod: "PUT",
  },
  fetchCandidateHistory: {
    name: "fetchCandidateHistory",
    urlEndPoint: `${baseUrlRoute}Applicants/GetApplicantHistoryByApplicantId?ApplicantId=`,
    httpMethod: "GET",
  },
  updateApplicantStage: {
    name: "updateApplicantStage",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/UpdateApplicantStage`,
    httpMethod: "PUT",
  },
  validateApplicantRLCStage: {
    name: "validateApplicantRLCStage",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/ValidateApplicantRLCStage`,
    httpMethod: "PUT",
  },
  rejectCandidate: {
    name: "rejectCandidate",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/RejectApplicantTransaction`,
    httpMethod: "PUT",
  },
  viewRLC: {
    name: "viewRLC",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/ViewRLC?JobId=`,
    httpMethod: "GET",
  },
  shortlistApplicant: {
    name: "shortlistApplicant",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/UpsertRecuritementTransaction`,
    httpMethod: "PUT",
  },
  fetchShortlistedApplicantDetail: {
    name: "fetchShortlistedApplicantDetail",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/GetRLCShortlistingDetails?RLCTransactionId=`,
    httpMethod: "GET",
  },
  scheduleInterviewDetails: {
    name: "scheduleInterviewDetails",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/ScheduleInterview`,
    httpMethod: "PUT",
  },
  fetchScheduledInterviewDetail: {
    name: "fetchScheduledInterviewDetail",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/GetRLCInterviewScheduleDetails?RLCTransactionId=`,
    httpMethod: "GET",
  },
  updateInterviewFeedback: {
    name: "updateInterviewFeedback",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/UpdateInterviewFeedBack`,
    httpMethod: "PUT",
  },
  shareInterviewFeedback: {
    name: "shareInterviewFeedback",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/ShareInterviewerFeedback`,
    httpMethod: "PUT",
  },
  markSelectApplicant: {
    name: "markSelectApplicant",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/MarkSelected`,
    httpMethod: "PUT",
  },
  requestApplicantDocuments: {
    name: "requestApplicantDocuments",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/RequestApplicantDocuments`,
    httpMethod: "PUT",
  },
  getRequestedApplicantDocuments: {
    name: "getRequestedApplicantDocuments",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/GetRequestedApplicantDocuments?RLCTransactionId=`,
    httpMethod: "GET",
  },
  uploadApplicantDocuments: {
    name: "uploadApplicantDocuments",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/UpsertRLCApplicantDocuments`,
    httpMethod: "PUT",
  },
  viewApplicantDocuments: {
    name: "viewApplicantDocuments",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/GetUploadedApplicantDocuments?RLCTransactionId=`,
    httpMethod: "GET",
  },
  shareApplicantDocuments: {
    name: "shareApplicantDocuments",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/ShareApplicantDocuments`,
    httpMethod: "PUT",
  },
  validateMakeOffer: {
    name: "validateMakeOffer",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/ValidateMakeAnOffer`,
    httpMethod: "PUT",
  },
  upsertOfferDetails: {
    name: "upsertOfferDetails",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/UpsertOfferDetails`,
    httpMethod: "PUT",
  },
  getOfferDetails: {
    name: "getOfferDetails",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/GetApplicantOfferDetailsByRLCId?RLCTransactionId=`,
    httpMethod: "GET",
  },
  shareOfferInternal: {
    name: "shareOfferInternal",
    urlEndPoint: `${baseUrlRoute}RLCTransaction/ShareOfferInformation`,
    httpMethod: "PUT",
  },
  getTransactionDetailsData: {
    name: "getTransactionDetailsData",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetDailyTransaction/`,
    httpMethod: "GET",
  },
  shareJobDescription: {
    name: "shareJobDescription",
    urlEndPoint: `${baseUrlRoute}Applicants/ShareJD`,
    httpMethod: "PUT",
  },
  getCandidateInterviewStage: {
    name: "getCandidateInterviewStage",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetCandidateInterviewStage/`,
    httpMethod: "GET",
  },
  getCandidateSelectedStage: {
    name: "getCandidateSelectedStage",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetCandidateSelectedStage/`,
    httpMethod: "GET",
  },
  getCandidateOfferedStage: {
    name: "getCandidateOfferedStage",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetCandidateOfferedStage/`,
    httpMethod: "GET",
  },
  // select job drop down
  getDailyMandatesAdded: {
    name: "getDailyMandatesAdded",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetDailyMandatesAdded/`,
    httpMethod: "GET",
  },
  // reports
  getPipelineReport: {
    name: "getPipelineReport",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetPipelineReport/`,
    httpMethod: "GET",
  },
  // recent applicants table
  getNewJoiners: {
    name: "getNewJoiners",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetNewJoiners/`,
    httpMethod: "GET",
  },
  getTransactionApplicationData: {
    name: "getTransactionApplicationData",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetRecentApplicants/`,
    httpMethod: "GET",
  },
  getDashboaredQuantityCounter: {
    name: "getDashboaredQuantityCounter",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetDashboaredQuantityCounter/`,
    httpMethod: "GET",
  },
  getUserDetailsByApplicableUserId: {
    name: "getUserDetailsByApplicableUserId",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetUserDetailsByApplicableUserId/`,
    httpMethod: "GET",
  },
  getJobDetailsByUserId: {
    name: "getJobDetailsByUserId",
    urlEndPoint: `${baseUrlRoute}Jobs/GetJobDetailsByUserId/`,
    httpMethod: "GET",
  },
  getHyreDashboardDetails: {
    name: "getHyreDashboardDetails",
    urlEndPoint: `${baseUrlRoute}Dashboard/GetHyreDashboardDetails/`,
    httpMethod: "GET",
  },
};

export const getApiUrl = (payload, name) => {
  if (payload?.queryProps)
    return {
      url: apiConfig[name].urlEndPoint + payload.queryProps,
      httpMethod: apiConfig[name].httpMethod,
    };
  return {
    url: apiConfig[name].urlEndPoint,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getDownloadFileUrl = (payload, name, accessToken) => {
  return {
    url: `${apiConfig[name].urlEndPoint}${payload.queryProps}/${accessToken}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getJobSharedWithUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}${payload.queryProps.jobRequirementId}&jobRequirementAssignmentId=${payload.queryProps.jobRequirementAssignmentId}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getJobListUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}pageIndex=${payload.queryProps.pageIndex}&pageSize=${payload.queryProps.pageSize}&PageCount=${payload.queryProps.pageCount}&JsonFilterObject=${payload.queryProps.jsonFilterObject}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getCandidateListUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}pageIndex=${payload.queryProps.pageIndex}&pageSize=${payload.queryProps.pageSize}&PageCount=${payload.queryProps.pageCount}&JsonFilterObject=${payload.queryProps.jsonFilterObject}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getTransactionApiUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.fromDate}/${payload.queryProps.toDate}/${payload.queryProps.type}/${payload.queryProps.filterValue}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getCandidateInterviewStage = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.fromDate}/${payload.queryProps.toDate}/${payload.queryProps.type}/${payload.queryProps.filterValue}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

// priya
export const getCandidateInterviewStageUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.fromDate}/${payload.queryProps.toDate}/${payload.queryProps.type}/${payload.queryProps.filterValue}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getCandidateSelectedStageUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.fromDate}/${payload.queryProps.toDate}/${payload.queryProps.type}/${payload.queryProps.filterValue}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getCandidateOfferedStageUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.fromDate}/${payload.queryProps.toDate}/${payload.queryProps.type}/${payload.queryProps.filterValue}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getDailyMandatesAddedUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.fromDate}/${payload.queryProps.toDate}/${payload.queryProps.type}/${payload.queryProps.filterValue}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getPipelineReportUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.fromDate}/${payload.queryProps.toDate}/${payload.queryProps.type}/${payload.queryProps.filterValue}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getNewJoinersUrl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.fromDate}/${payload.queryProps.toDate}/${payload.queryProps.type}/${payload.queryProps.filterValue}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};

export const getTransactionApplicationApiUrl = (name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};


export const getDashboardDetailsApi = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.fromDate}/${payload.queryProps.toDate}/${payload.queryProps.type}/${payload.queryProps.dataType}/${payload.queryProps.jobId}/${payload.queryProps.filterValue}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};


export const UserDetailsByApplicableUserIdAPIurl = (payload, name) => {
  return {
    url: `${apiConfig[name].urlEndPoint}/${payload.queryProps.type}`,
    httpMethod: apiConfig[name].httpMethod,
  };
};