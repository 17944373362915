/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { HashRouter } from "react-router-dom";
import { Switch, Route } from "react-router";
import { GlobalStyle } from "styles/global-styles";
// import { HomePage } from "./pages/HomePage/Loadable";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import { SessionExpiredPage } from "./components/SessionExpiredPage";
import { SomethingWentWrongPage } from "./components/SomethingWentWrongPage";
import { Login } from "./pages/Login/Loadable";
import { Dashboard } from "./pages/Dashboard/Loadable";
import { HiringRequests } from "./pages/HiringRequests/Loadable";
import { AddEditHiringRequests } from "./pages/HiringRequests/AddEditHiringRequests/Loadable";
import { Mandates } from "./pages/Mandates/Loadable";
import { AddEditMandate } from "./pages/Mandates/AddEditMandate/Loadable";
import { RLC } from "./pages/Mandates/RLC/Loadable";
import { AssignJob } from "./pages/Mandates/MandateList/AssignJob/Loadable";
import { ShareJob } from "./pages/Mandates/MandateList/ShareJob/Loadable";
import { Candidates } from "./pages/Candidates/Loadable";
import { AddEditCandidate } from "./pages/Candidates/AddEditCandidate/Loadable";
import { CapturePreference } from "./pages/Candidates/CapturePreference/index";
import { SearchProfiles } from "./pages/SearchProfiles/Loadable";
import { Reports } from "./pages/Reports/Loadable";
import { useTranslation } from "react-i18next";

export function App() {
  const { i18n } = useTranslation();
  return (
    <HashRouter>
      <Helmet
        // titleTemplate="%s - React Boilerplate"
        defaultTitle="Hyre 2.0"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/hiring-requests" component={HiringRequests} />
        <Route
          exact
          path="/hiring-requests/add-hiring-request"
          component={AddEditHiringRequests}
        />
        <Route
          exact
          path="/hiring-requests/edit-hiring-request/:requestId"
          component={AddEditHiringRequests}
        />
        <Route exact path="/mandates" component={Mandates} />
        <Route exact path="/mandates/add-mandate" component={AddEditMandate} />
        <Route
          exact
          path="/mandates/edit-mandate/:mandateId"
          component={AddEditMandate}
        />
        <Route
          exact
          path="/mandates/clone-mandate/:mandateId"
          component={AddEditMandate}
        />
        <Route exact path="/mandates/rlc/:mandateId" component={RLC} />
        <Route
          exact
          path="/mandates/assign-job/:mandateId"
          component={AssignJob}
        />
        <Route
          exact
          path="/mandates/share-job/:mandateId"
          component={ShareJob}
        />
        <Route exact path="/candidates" component={Candidates} />
        <Route
          exact
          path="/candidates/add-candidate"
          component={AddEditCandidate}
        />
        <Route
          exact
          path="/candidates/edit-candidate/:candidateId"
          component={AddEditCandidate}
        />
        <Route
          exact
          path="/candidates/edit-candidate/:candidateId"
          component={AddEditCandidate}
        />
        <Route
          exact
          path="/candidates/capture-preference/:candidateId"
          component={CapturePreference}
        />
        <Route exact path="/profiles" component={SearchProfiles} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/session-expired" component={SessionExpiredPage} />
        <Route
          exact
          path="/something-went-wrong"
          component={SomethingWentWrongPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </HashRouter>
  );
}
