/**
 * Asynchronously loads the component for ReportsPage
 */

import { lazyLoad } from "utils/loadable";

export const Reports = lazyLoad(
  () => import("./index"),
  (module) => module.Reports
);
